import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../Service/service";

const ServiceStatusDashboard = () => {
  const [status, setStatus] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedStatus, setEditedStatus] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const styles = {
    container: {
      padding: "20px",
      maxWidth: "800px",
      margin: "0 auto",
      marginTop: "20px",
    },
    card: {
      border: "1px solid #e5e7eb",
      borderRadius: "8px",
      backgroundColor: "white",
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    },
    header: {
      padding: "20px",
      borderBottom: "1px solid #e5e7eb",
    },
    headerContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      margin: 0,
    },
    button: {
      padding: "8px 16px",
      borderRadius: "6px",
      border: "none",
      color: "white",
      cursor: "pointer",
      fontWeight: "500",
    },
    editButton: {
      backgroundColor: "#3b82f6",
    },
    saveButton: {
      backgroundColor: "#22c55e",
    },
    content: {
      padding: "20px",
    },
    alert: {
      padding: "12px",
      borderRadius: "6px",
      marginBottom: "20px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    successAlert: {
      backgroundColor: "#dcfce7",
      color: "#166534",
    },
    errorAlert: {
      backgroundColor: "#fee2e2",
      color: "#dc2626",
    },
    section: {
      marginBottom: "24px",
    },
    sectionTitle: {
      fontSize: "18px",
      fontWeight: "600",
      marginBottom: "12px",
    },
    switchContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      margin: "8px 0",
    },
    switch: {
      position: "relative",
      width: "44px",
      height: "24px",
      backgroundColor: "#e5e7eb",
      borderRadius: "12px",
      cursor: "pointer",
      transition: "background-color 0.2s",
    },
    switchActive: {
      backgroundColor: "#3b82f6",
    },
    switchHandle: {
      position: "absolute",
      top: "2px",
      left: "2px",
      width: "20px",
      height: "20px",
      backgroundColor: "white",
      borderRadius: "50%",
      transition: "transform 0.2s",
    },
    switchHandleActive: {
      transform: "translateX(20px)",
    },
    inputContainer: {
      marginTop: "10px",
    },
    label: {
      display: "block",
      marginBottom: "5px",
      fontWeight: "500",
    },
    input: {
      width: "100%",
      padding: "8px",
      border: "1px solid #e5e7eb",
      borderRadius: "6px",
      fontSize: "14px",
    },
    disabledInput: {
      backgroundColor: "#f3f4f6",
      cursor: "not-allowed",
    },
  };

  useEffect(() => {
    fetchServiceStatus();
  }, []);

  const fetchServiceStatus = async () => {
    try {
      const response = await fetch(`${BASE_URL}/service-status`);
      const data = await response.json();
      setStatus(data);
      setEditedStatus(data);
    } catch (error) {
      showAlert("Failed to fetch service status", "error");
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`${BASE_URL}/service-status`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          is_available: editedStatus.isAvailable,
          is_available_web: editedStatus.isAvailable_Web,
          status_message: editedStatus.statusMessage,
          maintenance_scheduled: editedStatus.maintenanceSchedule.isScheduled,
          maintenance_date: editedStatus.maintenanceSchedule.date,
          maintenance_reason_mobile:
            editedStatus.maintenanceSchedule.reason_mobile,
          maintenance_reason_web_text1:
            editedStatus.maintenanceSchedule.reason_web_text1,
          maintenance_reason_web_text2:
            editedStatus.maintenanceSchedule.reason_web_text2,
          is_service_regular: editedStatus.serviceSchedule.isServiceRegular,
          is_service_basic: editedStatus.serviceSchedule.isServiceBasic,
          is_service_healthy: editedStatus.serviceSchedule.isServiceHealty,
        }),
      });

      if (response.ok) {
        setStatus(editedStatus);
        setIsEditing(false);
        showAlert("Service status updated successfully", "success");
      }
    } catch (error) {
      showAlert("Failed to update service status", "error");
    }
  };

  const showAlert = (message, type) => {
    setAlert({ show: true, message, type });
    setTimeout(() => setAlert({ show: false, message: "", type: "" }), 3000);
  };

  const Switch = ({ checked, onChange, disabled }) => (
    <div
      style={{
        ...styles.switch,
        ...(checked ? styles.switchActive : {}),
        opacity: disabled ? 0.6 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      onClick={() => !disabled && onChange(!checked)}
    >
      <div
        style={{
          ...styles.switchHandle,
          ...(checked ? styles.switchHandleActive : {}),
        }}
      />
    </div>
  );

  if (!status)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        Loading...
      </div>
    );

  return (
    <>
      <h1
        style={{
          margin: "24px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Web/Mobile Notification Control System
      </h1>
      <div style={styles.container}>
        <div style={styles.card}>
          <div style={styles.header}>
            <div style={styles.headerContent}>
              <h1 style={styles.title}>Service Status Dashboard</h1>
              <button
                onClick={() =>
                  isEditing ? handleUpdate() : setIsEditing(true)
                }
                style={{
                  ...styles.button,
                  ...(isEditing ? styles.saveButton : styles.editButton),
                }}
              >
                {isEditing ? "Save Changes" : "Edit Status"}
              </button>
            </div>
          </div>

          <div style={styles.content}>
            {alert.show && (
              <div
                style={{
                  ...styles.alert,
                  ...(alert.type === "success"
                    ? styles.successAlert
                    : styles.errorAlert),
                }}
              >
                {alert.message}
              </div>
            )}

            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>General Status</h3>
              <div style={styles.switchContainer}>
                <Switch
                  checked={
                    isEditing ? editedStatus.isAvailable : status.isAvailable
                  }
                  onChange={(val) =>
                    setEditedStatus({ ...editedStatus, isAvailable: val })
                  }
                  disabled={!isEditing}
                />
                <span>Mobile Service </span>
              </div>
              <div style={styles.switchContainer}>
                <Switch
                  checked={
                    isEditing
                      ? editedStatus.isAvailable_Web
                      : status.isAvailable_Web
                  }
                  onChange={(val) =>
                    setEditedStatus({ ...editedStatus, isAvailable_Web: val })
                  }
                  disabled={!isEditing}
                />
                <span>Web Service </span>
              </div>
            </div>

            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>Maintenance Schedule</h3>
              <div style={styles.switchContainer}>
                <Switch
                  checked={
                    isEditing
                      ? editedStatus.maintenanceSchedule.isScheduled
                      : status.maintenanceSchedule.isScheduled
                  }
                  onChange={(val) =>
                    setEditedStatus({
                      ...editedStatus,
                      maintenanceSchedule: {
                        ...editedStatus.maintenanceSchedule,
                        isScheduled: val,
                      },
                    })
                  }
                  disabled={!isEditing}
                />
                <span>Maintenance Scheduled</span>
              </div>
              <div style={styles.inputContainer}>
                <label style={styles.label}>Maintenance Date</label>
                <input
                  type="date"
                  style={{
                    ...styles.input,
                    ...(isEditing ? {} : styles.disabledInput),
                  }}
                  value={
                    isEditing
                      ? editedStatus.maintenanceSchedule.date
                      : status.maintenanceSchedule.date
                  }
                  onChange={(e) =>
                    setEditedStatus({
                      ...editedStatus,
                      maintenanceSchedule: {
                        ...editedStatus.maintenanceSchedule,
                        date: e.target.value,
                      },
                    })
                  }
                  disabled={!isEditing}
                />
              </div>
              <div style={styles.inputContainer}>
                <label style={styles.label}>Message Common Web/Mobile</label>
                <input
                  style={{
                    ...styles.input,
                    ...(isEditing ? {} : styles.disabledInput),
                  }}
                  value={
                    isEditing
                      ? editedStatus.statusMessage
                      : status.statusMessage
                  }
                  onChange={(e) =>
                    setEditedStatus({
                      ...editedStatus,
                      statusMessage: e.target.value,
                    })
                  }
                  disabled={!isEditing}
                />
              </div>
              <div style={styles.inputContainer}>
                <label style={styles.label}>Mobile  Text Main</label>
                <input
                  style={{
                    ...styles.input,
                    ...(isEditing ? {} : styles.disabledInput),
                  }}
                  value={
                    isEditing
                      ? editedStatus.maintenanceSchedule.reason_mobile
                      : status.maintenanceSchedule.reason_mobile
                  }
                  onChange={(e) =>
                    setEditedStatus({
                      ...editedStatus,
                      maintenanceSchedule: {
                        ...editedStatus.maintenanceSchedule,
                        reason_mobile: e.target.value,
                      },
                    })
                  }
                  disabled={!isEditing}
                />
              </div>
              <div style={styles.inputContainer}>
                <label style={styles.label}>
                  Web (Text 1)
                </label>
                <input
                  style={{
                    ...styles.input,
                    ...(isEditing ? {} : styles.disabledInput),
                  }}
                  value={
                    isEditing
                      ? editedStatus.maintenanceSchedule.reason_web_text1
                      : status.maintenanceSchedule.reason_web_text1
                  }
                  onChange={(e) =>
                    setEditedStatus({
                      ...editedStatus,
                      maintenanceSchedule: {
                        ...editedStatus.maintenanceSchedule,
                        reason_web_text1: e.target.value,
                      },
                    })
                  }
                  disabled={!isEditing}
                />
              </div>
              <div style={styles.inputContainer}>
                <label style={styles.label}>
                  Web (Text 2)
                </label>
                <input
                  style={{
                    ...styles.input,
                    ...(isEditing ? {} : styles.disabledInput),
                  }}
                  value={
                    isEditing
                      ? editedStatus.maintenanceSchedule.reason_web_text2
                      : status.maintenanceSchedule.reason_web_text2
                  }
                  onChange={(e) =>
                    setEditedStatus({
                      ...editedStatus,
                      maintenanceSchedule: {
                        ...editedStatus.maintenanceSchedule,
                        reason_web_text2: e.target.value,
                      },
                    })
                  }
                  disabled={!isEditing}
                />
              </div>
            </div>

            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>Service Schedule</h3>
              <div style={styles.switchContainer}>
                <Switch
                  checked={
                    isEditing
                      ? editedStatus.serviceSchedule.isServiceRegular
                      : status.serviceSchedule.isServiceRegular
                  }
                  onChange={(val) =>
                    setEditedStatus({
                      ...editedStatus,
                      serviceSchedule: {
                        ...editedStatus.serviceSchedule,
                        isServiceRegular: val,
                      },
                    })
                  }
                  disabled={!isEditing}
                />
                <span>Regular Service</span>
              </div>
              <div style={styles.switchContainer}>
                <Switch
                  checked={
                    isEditing
                      ? editedStatus.serviceSchedule.isServiceBasic
                      : status.serviceSchedule.isServiceBasic
                  }
                  onChange={(val) =>
                    setEditedStatus({
                      ...editedStatus,
                      serviceSchedule: {
                        ...editedStatus.serviceSchedule,
                        isServiceBasic: val,
                      },
                    })
                  }
                  disabled={!isEditing}
                />
                <span>Basic Service</span>
              </div>
              <div style={styles.switchContainer}>
                <Switch
                  checked={
                    isEditing
                      ? editedStatus.serviceSchedule.isServiceHealty
                      : status.serviceSchedule.isServiceHealty
                  }
                  onChange={(val) =>
                    setEditedStatus({
                      ...editedStatus,
                      serviceSchedule: {
                        ...editedStatus.serviceSchedule,
                        isServiceHealty: val,
                      },
                    })
                  }
                  disabled={!isEditing}
                />
                <span>Healthy Service</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceStatusDashboard;
