import React from 'react';

const CustomConfirmDialog = ({ title, message, onConfirm, onCancel }) => {
  return (
    <div style={styles.dialogContainer}>
      <div style={styles.dialog}>
        <h2>{title}</h2>
        <p>{message}</p>
        <div style={styles.buttons}>
          <button style={styles.confirmButton} onClick={onConfirm}>Yes</button>
          <button style={styles.cancelButton} onClick={onCancel}>No</button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  dialogContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Added high z-index to ensure it appears on top
  },
  dialog: {
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    width: '100%',
    zIndex: 10000, // Added even higher z-index for the dialog box
    position: 'relative', // Added to create a new stacking context
  },
  title: {
    margin: '0 0 15px 0',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  message: {
    margin: '0 0 20px 0',
    fontSize: '1rem',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px', // Added gap between buttons
  },
  confirmButton: {
    backgroundColor: 'red',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    fontSize: '1rem',
  },
  cancelButton: {
    backgroundColor: 'green',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    fontSize: '1rem',
  },
};

export default CustomConfirmDialog;
