import React from "react";

const styles = {
  bannerContainer: {
    width: "100%",
    // background: 'linear-gradient(90deg, #FFC107 0%, #FFD700 50%, #FFC107 100%)',
    // padding: '16px',
    // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  innerWrapper: {
    maxWidth: "1024px",
    margin: "0 auto",
    textAlign: "center",
  },
  messageBox: {
    background: "linear-gradient(90deg, #FFC107 0%, #FFD700 50%, #FFC107 100%)",
    borderRadius: "8px",
    padding: "24px",
    backdropFilter: "blur(4px)",
  },
  title: {
    fontSize: "32px",
    fontWeight: "bold",
    marginBottom: "8px",
  },
  mainMessage: {
    marginBottom: "12px",
    fontSize: "16px",
  },
  subMessage: {
    fontWeight: "500",
    fontSize: "16px",
  },
};

const NewYearBanner = ({ serviceStatus }) => {

  return (
    <div style={styles.bannerContainer}>
      <div style={styles.innerWrapper}>
        <div style={styles.messageBox}>
          <h2 style={styles.title}>{serviceStatus.statusMessage}</h2>
          <p style={styles.mainMessage}>
            {serviceStatus.maintenanceSchedule.reason_web_text1}
          </p>
          <p style={styles.subMessage}>
          {serviceStatus.maintenanceSchedule.reason_web_text2}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewYearBanner;
