import React from 'react';
import { Loader2 } from 'lucide-react';

const LoadingOverlay = () => {
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    backdropFilter: 'blur(4px)',
    WebkitBackdropFilter: 'blur(4px)', // For Safari support
    zIndex: 999999,
    pointerEvents: 'auto',
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '24px',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    zIndex: 1000000,
  };

  const textStyle = {
    fontSize: '18px',
    fontWeight: 600,
    color: '#374151',
    margin: 0,
  };

  // Add keyframes for spin animation
  React.useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.textContent = `
      @keyframes spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
      }
    `;
    document.head.appendChild(styleSheet);
    
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  return (
    <div style={overlayStyle}>
      <div style={containerStyle}>
        <Loader2 
          style={{
            width: '48px',
            height: '48px',
            color: '#FAC105',
            animation: 'spin 1s linear infinite',
          }}
        />
        <p style={textStyle}>Processing Refund...</p>
      </div>
    </div>
  );
};

export default LoadingOverlay;