import React, { useState, useEffect } from "react";
import { Activity, CheckCircle, Clock } from "lucide-react";
import { BASE_URL } from "../../Service/service";
import { Colors } from "../../Colors";

const OrderStatusDashboard = () => {
  const [stats, setStats] = useState({
    active: 0,
    assigned: 0,
    all: 0,
  });

  const fetchStats = async () => {
    try {
      const response = await fetch(`${BASE_URL}/userAddon`);
      const data = await response.json();

      setStats({
        active: data.filter((user) => user.status === true).length,
        assigned: data.filter((user) => user.status === false).length,
        all: data.length,
      });
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  useEffect(() => {
    fetchStats();
    // Poll every 30 seconds for updates
    const interval = setInterval(fetchStats, 30000);
    return () => clearInterval(interval);
  }, []);

  const statsConfig = [
    {
      title: "Active Orders",
      value: stats.active,
      icon: Clock,
      description: "Pending assignment",
      color: "#3B82F6",
      bgColor: "#EFF6FF",
    },
    {
      title: "Assigned Orders",
      value: stats.assigned,
      icon: CheckCircle,
      description: "With delivery partners",
      color: "#22C55E",
      bgColor: "#F0FDF4",
    },
    {
      title: "Total Orders",
      value: stats.all,
      icon: Activity,
      description: "All time orders",
      color: "#A855F7",
      bgColor: "#FAF5FF",
    },
  ];

  return (
    <div style={styles.grid}>
      {statsConfig.map((stat, index) => {
        const Icon = stat.icon;
        return (
          <div key={index} style={styles.card}>
            <div style={styles.cardHeader}>
              <div style={styles.title}>{stat.title}</div>
              <Icon style={{ ...styles.icon, color: stat.color }} />
            </div>
            <div style={styles.cardContent}>
              <div style={styles.value}>{stat.value}</div>
              <p style={styles.description}>{stat.description}</p>
              <div
                style={{
                  ...styles.backgroundShape,
                  backgroundColor: stat.bgColor,
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "1rem",
    padding: "0px 16px 16px 16px",
  },
  card: {
    position: "relative",
    padding: "1.5rem",
    borderRadius: "0.5rem",
    backgroundColor: "white",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
    overflow: "hidden",
    background:"linear-gradient(#FAC105,rgb(255, 255, 255))",
    border: "1px solid #2165CC",
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "0.5rem",
  },
  title: {
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#4B5563",
  },
  icon: {
    width: "1.25rem",
    height: "1.25rem",
  },
  cardContent: {
    position: "relative",
  },
  value: {
    fontSize: "1.875rem",
    fontWeight: "bold",
    marginBottom: "0.25rem",
  },
  description: {
    fontSize: "0.75rem",
    color: "#4B5563",
  },
  backgroundShape: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "8rem",
    height: "8rem",
    borderRadius: "50%",
    transform: "translate(25%, -25%)",
    opacity: 0.2,
  },
};

export default OrderStatusDashboard;
