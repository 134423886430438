import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import moment from "moment";
import clsx from "clsx";
import './Activeorders.css'
import ButtonArrow from "../../../src/SVG/ButtonArrow";
import ButtonRight from "../../../src/SVG/ButtonRight";

const DateSlider = (date) => {
  const [selectedDate, setSelectedDate] = useState(true);
  const [current, setCurrent] = useState("");
  const fromDate = moment();
  const [TodayDay, setTodayDay] = useState(fromDate.format('DD'))
  const next = moment().add(2, "weeks").endOf("week");
  const prev = moment().subtract(-1, "week").startOf("week").isoWeekday(1);
  let today = moment().format("MMMM  YYYY");
  let weekForToday = moment().endOf("week").isoWeekday(1).format("DDMMM");
  // var TodayDay = fromDate.format('dddd')
  // var TodayMonth = fromDate.format('MMMM YYYY')
  // var TodayDay=fromDate.format('DD')


  var enumerateDaysBetweenDates = function (startDate, endDate) {
    var now = startDate,
      dates = [];
    while (now.isSameOrBefore(endDate)) {
      var obj = {
        date: now.format("dddd"),
        day: now.format("DD"),
        month: now.format("MMMM YYYY"),
      };
      dates.push(obj);
      now.add(1, "days");
    }
    return dates;
  };

  enumerateDaysBetweenDates(fromDate, next);
  const getOneMonth = enumerateDaysBetweenDates(prev, fromDate);
 
  const selectedDateFunc = (value, index, day) => {
    setCurrent(index);
    setSelectedDate(value);
    setTodayDay('')
  };
  useEffect(() => {
    setSelectedDate(today);
    setCurrent("");
    // selectedDateFunc(
    //   today,
    //   getOneMonth.findIndex((x) => x.day + x.month === today)
    // );
  }, []);
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow_padding">
        <div className="arrow_prev" >
          <ButtonArrow onClick={onClick} styles={{ cursor: 'pointer' }} />
        </div>
      </div>
    );
  };

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow_padding">
        <div className="arrow_right" >
          <ButtonRight onClick={onClick} styles={{ cursor: 'pointer' }} />
        </div>
      </div>
    );
  };
  const settings = {
    speed: 500,
    Infinity: false,
    slidesToShow: 7,
    slidesToScroll: 7,
    dots: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    fromDate: weekForToday
    // initialSlide: getOneMonth.findIndex((x) => x.day + x.month === "10Aug"),
  };

  const sliders = () => {
    return getOneMonth.map((i, index) => {
      return (
        <div>
          <div className={clsx(index === current ? "date selected" : "date")}
            key={index}
            onClick={(e) => selectedDateFunc(i.month, index, i.day)}
            style={{ background: i.day === TodayDay ? `linear-gradient(#FAC105, #FFD814)` : "" }}>
            <div className="i_date" >
              {i.date}
            </div>
            <div className="i_day" >
              {i.day}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="dateSlider" >

      <div style={{ display: "flex", justifyContent: 'space-between' }}>

        {/* <h3 className="header_text">Active order</h3> */}

        <p
          style={{
            textAlign: "right",
            marginRight: "40px",
            marginBottom: "10px",
            fontWeight: "700",
          }}
        >
          {selectedDate}
        </p>

      </div>

      <Slider
        {...settings}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {sliders()}
      </Slider>

    </div>

  );
};

export default DateSlider;
