// import React, { useState, useEffect } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { Colors } from "../../Colors";
// import { AiOutlineCloudUpload } from "react-icons/ai";
// import { BASE_URL } from "../../Service/service";
// import DashboardHome from "./../../Home/DashboardHome";
// import NavigationBar from "../../NavigationBar"
// const ImageGallery = () => {
//   const [imageUrls, setImageUrls] = useState([]);
//   const [uploading, setUploading] = useState(false);

//   useEffect(() => {
//     fetch(`${BASE_URL}/listImages`)
//       .then((response) => response.json())
//       .then((data) => {
//         if (Array.isArray(data)) {
//           setImageUrls(data);
//         }
//       })
//       .catch((error) => console.error("Error fetching images:", error));
//   }, []);

//   const deleteImage = (id) => {
//     fetch(`${BASE_URL}/deleteImage/${id}`, {
//       method: "DELETE",
//     })
//       .then((response) => {
//         if (response.ok) {
//           // Remove the deleted image from the state
//           setImageUrls((prevImages) =>
//             prevImages.filter((image) => image.id !== id)
//           );
//         } else {
//           console.log("Failed to delete image");
//         }
//       })
//       .catch((error) => console.error("Error deleting image:", error));
//   };

//   const getImageName = (url) => {
//     const parts = url.split("/");
//     const filename = parts[parts.length - 1];
//     return filename.split(".")[0];
//   };

//   const handleImageUpload = (event) => {
//     const file = event.target.files[0];
//     const formData = new FormData();
//     formData.append("image", file);

//     fetch(`${BASE_URL}/uploadImage`, {
//       method: "POST",
//       body: formData,
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status) {
//           toast.success(data.message);
//           // Update image immediately on successful upload
//           setImageUrls((prevImages) => [
//             ...prevImages,
//             { id: data.image.id, image_url: data.image.image_url },
//           ]);
//         } else {
//           toast.error(data.message);
//         }
//         setUploading(false);
//       })
//       .catch((error) => {
//         console.log("Error uploading image:", error);
//         toast.error("Error uploading image");
//         setUploading(false);
//       });
//   };

//   return (
//     <div>
//             <NavigationBar />
//             <div style={{ margin: "24px" }}>
//       <h1 style={{ margin: "24px", display: "flex", justifyContent: "center" }}>
//         Image Gallery
//       </h1>
//       <ToastContainer />
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "flex-start",
//           alignItems: "center",
//           paddingLeft: "20px",
//         }}
//       >
//         <label
//           htmlFor="file-upload"
//           style={{
//             marginRight: "10px",
//             cursor: "pointer",
//             background: `linear-gradient(${Colors.Primary}, ${Colors.Secondary})`,
//             color: "black",
//             padding: "10px 20px",
//             borderRadius: "4px",
//             boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
//             fontWeight: "600",
//             marginBottom: "16px",
//           }}
//         >
//           <AiOutlineCloudUpload size={20} style={{ marginRight: "10px" }} />
//           Upload Image
//           <input
//             id="file-upload"
//             type="file"
//             accept="image/*"
//             onChange={handleImageUpload}
//             style={{ display: "none" }}
//           />
//         </label>
//         {uploading && <p>Uploading...</p>}
//       </div>
//       <div
//         style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
//       >
//         {imageUrls.map((image, index) => (
//           <div
//             key={index}
//             style={{
//               margin: "10px",
//               boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
//               borderRadius: "8px",
//               overflow: "hidden",
//               position: "relative",
//             }}
//           >
//             <img
//               src={image.image_url}
//               alt={`Image ${index}`}
//               style={{ width: "200px", height: "200px", objectFit: "cover" }}
//             />
//             <div style={{ backgroundColor: "#f0f0f0", padding: "10px" }}>
//               <p style={{ margin: 0, fontWeight: "bold" }}>
//                 {getImageName(image.image_url)}
//               </p>
//             </div>
//             <button
//               onClick={() => deleteImage(image.id)}
//               style={{
//                 position: "absolute",
//                 bottom: "10px",
//                 right: "10px",
//                 padding: "3px 8px",
//                 backgroundColor: "red",
//                 color: "white",
//                 border: "none",
//                 borderRadius: "4px",
//                 cursor: "pointer",
//               }}
//             >
//               Delete
//             </button>
//           </div>
//         ))}
//       </div>
//     </div>
//     </div>

//   );
// };

// export default ImageGallery;


import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Colors } from "../../Colors";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BASE_URL } from "../../Service/service";
import NavigationBar from "../../NavigationBar";

const ImageGallery = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [deleteConfirmations, setDeleteConfirmations] = useState({});

  useEffect(() => {
    fetch(`${BASE_URL}/listImages`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setImageUrls(data);
        }
      })
      .catch((error) => console.error("Error fetching images:", error));
  }, []);

  const deleteImage = (id) => {
    if (deleteConfirmations[id] === "Delete") {
      fetch(`${BASE_URL}/deleteImage/${id}`, {
        method: "DELETE",
      })
        .then((response) => {
          if (response.ok) {
            setImageUrls((prevImages) =>
              prevImages.filter((image) => image.id !== id)
            );
            toast.success("Image deleted successfully");
          } else {
            toast.error("Failed to delete image");
          }
        })
        .catch((error) => {
          console.error("Error deleting image:", error);
          toast.error("Error deleting image");
        });
    } else {
      toast.error("Please type 'Delete' to confirm");
    }
    // Reset the confirmation state for this image
    setDeleteConfirmations((prev) => ({ ...prev, [id]: "" }));
  };

  const handleDeleteConfirmationChange = (id, value) => {
    setDeleteConfirmations((prev) => ({ ...prev, [id]: value }));
  };

  const getImageName = (url) => {
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    return filename.split(".")[0];
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    fetch(`${BASE_URL}/uploadImage`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          toast.success(data.message);
          setImageUrls((prevImages) => [
            ...prevImages,
            { id: data.image.id, image_url: data.image.image_url },
          ]);
        } else {
          toast.error(data.message);
        }
        setUploading(false);
      })
      .catch((error) => {
        // console.log("Error uploading image:", error);
        toast.error("Error uploading image");
        setUploading(false);
      });
  };

  return (
    <div style={{minHeight: "100vh", }}>
      {/* <NavigationBar /> */}
      <div style={{ margin: "24px" }}>
        <h1 style={{ margin: "24px", display: "flex", justifyContent: "center" }}>
          Saved Images
        </h1>
        <ToastContainer />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingLeft: "20px",
          }}
        >
          <label
            htmlFor="file-upload"
            style={{
              marginRight: "10px",
              cursor: "pointer",
              background: `linear-gradient(${Colors.Primary}, ${Colors.Secondary})`,
              color: "black",
              padding: "10px 20px",
              borderRadius: "4px",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              fontWeight: "600",
              marginBottom: "16px",
            }}
          >
            <AiOutlineCloudUpload size={20} style={{ marginRight: "10px" }} />
            Upload Image
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
          </label>
          {uploading && <p>Uploading...</p>}
        </div>
        <div
          style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
        >
          {imageUrls.map((image, index) => (
            <div
              key={index}
              style={{
                margin: "10px",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                borderRadius: "8px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <img
                src={image.image_url}
                alt={`Image ${index}`}
                style={{ width: "250px", height: "200px", objectFit: "cover" }}
              />
              <div style={{ backgroundColor: "#f0f0f0", padding: "6px" }}>
                <p style={{ margin: 0, fontWeight: "bold" }}>
                  {getImageName(image.image_url)}
                </p>
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
              </div>
              <input
                  type="text"
                  placeholder="Type 'Delete'"
                  value={deleteConfirmations[image.id] || ""}
                  onChange={(e) => handleDeleteConfirmationChange(image.id, e.target.value)}
                  style={{
                    marginRight: "5px",
                    padding: "3px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    marginLeft:"10px",
                  marginBottom:"10px"
                  }}
                />
                <button
                  onClick={() => deleteImage(image.id)}
                  style={{
                    padding: "3px 8px",
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Delete
                </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
