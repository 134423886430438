import React, { useEffect, useRef, useState } from "react";
import { Colors } from "../../Colors";
import ImageEdit from "../../SVG/ImageEdit";
import VegIcon from "../../SVG/VegIcon";
import Non_Veg from "../../SVG/Non_Veg";
import PrimaryButton from "../UTIL/Buttons/PrimaryButton";
import SecondaryButton from "../UTIL/Buttons/SecondaryButton";
import Save from "../../SVG/Save";
import Cancel from "../../SVG/Save";
import Switch from "react-switch";
import Toggle from "react-toggle";
import { useDispatch } from "react-redux";
import { addonsAction } from "../../store/addons";

const typeItems = ["Select Veg/Non-Veg", "Veg", "Non-Veg"];
const ingredientItem = ["Item 1", "1", "2"];
const ingredientItems = ["Item 2", "1", "2"];
function Addnew({ addModal, setAddModal }) {
  const styles = {
    container: {
      margin: "2rem",
      backgroundColor: "white",
      borderRadius: "1rem",
    },
    h2: {
      fontSize: "16px",
      fontWeight: "700",
    },
    innerContainer: {
      display: "flex",
      justifyContent: "space-between",
      flex: 1,
    },

    detailsContainer: {
      width: "48%",
    },
    imageOuterContainer: {
      flex: 1,
      display:"flex",
      justifyContent: 'center',
      alignItems: 'center',
    },
    imageContainer: {
      height:'310px',
      margin:'2rem 0rem 0rem 2rem',
      border: '1px solid #0c0a0a',
      borderRadius: '16px',
      flex: 1,
      display: "flex",
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    image: {
      height: '100%',
      width: '100%',
      borderRadius: '1rem',
    },
    imageEditContainer: {
      position: 'absolute',
      cursor: "pointer",
      margin:'4px'
    },
    preferenceContainer: {
      display: "flex",
      justifyContent: "space-between",
      flex: 1,
      border: `2px solid ${Colors.Primary}`,
      borderRadius: "0.5rem",
      height: "2.5rem",
      width: "100%",
    },
    itemStyle: {
      padding: "1rem 2rem 1rem 2rem",
      flex: 1,
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    itemText: {
      fontSize: "13px",
    },
    dropDownContainer: {
      border: `2px solid ${Colors.Primary}`,
      borderRadius: "0.5rem",
      height: "2.5rem",
      width: "100%",
    },
    detailsContainer: {
      flex: 1,
    },
    detailsItemContainer: {
      marginTop: "0.5rem",
    },


    actionContainer: {
      // height: '2.5rem',
      width: "100%",
      display: "flex",
      marginTop: "1rem",
    },
    defaultContainer: {
      width: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    saveCancelContainer: {
      display: "flex",
      alignItems: "center",
      width: "50%",
      justifyContent: "space-around",
    },
    textContainer: {
      marginLeft: "1rem",
    },
    boldText: {
      fontWeight: 600,
    },
    title: {
      // marginTop: 20,
    },
  };
  const preferenceItems = [
    {
      icon: <VegIcon />,
      type: "Vegeterian",
    },
    {
      icon: <Non_Veg />,
      type: "Non-Vegeterian",
    },
  ];
  const ref = useRef();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [close, setClose] = useState(true);
  const [image, setImage] = useState(null);
  const [active, setActive] = useState(null);
  const [preference, setPreference] = useState(null);
  const [type, setType] = useState(null);
  const [ingredient, setIngredient] = useState(null);
  const [price, setPrice] = useState(null);
  const handleClick = (e) => {
    ref.current.click();
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  const clickHandler = (index) => {
    for (var i = 0; i < preferenceItems.length; i++) {
      if (i === index) {
        setActive(i);
      }
    }
  };
  useEffect(() => {
    if (active === 0) {
      setPreference("Veg");
    }
    if (active === 1) {
      setPreference("Non-Veg");
    }
  }, [active]);
  const cancelHandler = () => {
    setAddModal(false);
  };
  console.log("price : " + type);
  const saveHandler = () => {
    dispatch(
      addonsAction.addonsAdded({
        preference: preference,
        type: type,
        ingredient: ingredient,
        price: price,
        image: image,
      })
    );
  };
  return (
    <div style={styles.container}>
      <h2 style={styles.h2}>Add new Item</h2>
      <div style={styles.innerContainer}>
        <div style={styles.detailsContainer}>
          <div style={styles.detailsItemContainer}>
            <span style={styles.title}>Select type</span>
            <select
              style={styles.dropDownContainer}
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              {typeItems.map((item, index) => (
                <option
                  key={index}
                  style={styles.dropDownContainer}
                  value={item}
                >
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div style={styles.detailsItemContainer}>
            <span style={styles.title}>Name of first item</span>
            <select
              style={styles.dropDownContainer}
              onChange={(e) => setIngredient(e.target.value)}
              value={ingredient}
            >
              {ingredientItem.map((item, index) => (
                <option
                  key={index}
                  style={styles.dropDownContainer}
                  value={item}
                >
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div style={styles.detailsItemContainer}>
            <span style={styles.title}>Name of item</span>
            <select
              style={styles.dropDownContainer}
              onChange={(e) => setIngredient(e.target.value)}
              value={ingredient}
            >
              {ingredientItems.map((item, index) => (
                <option
                  key={index}
                  style={styles.dropDownContainer}
                  value={item}
                >
                  {item}
                </option>
              ))}
            </select>
          </div>

          <div style={styles.detailsItemContainer}>
            <span style={styles.title}>Name of item</span>
            <div>
              <input
                type="text"
                style={{
                  height: '100px',
                  width: '100%',
                  borderRadius: "10px",
                  border: `2px solid ${Colors.Primary}`,
                }}
              />
            </div>
          </div>
        </div>
        <div style={styles.imageOuterContainer} >
        
          <div style={styles.imageContainer}>
            <img src={image} style={styles.image} />
            <div style={styles.imageEditContainer} onClick={handleClick}>
              <ImageEdit style={styles.imageEdit} />
              <input type="file" ref={ref} style={{ display: 'none' }} onChange={onImageChange} />
            </div>
          </div>
        </div>
      </div>
      <div style={styles.actionContainer}>
        <div style={styles.defaultContainer}>
          <div></div>
        </div>
        <div style={styles.saveCancelContainer}>
          <SecondaryButton
            text={"Cancel"}
            icon={<Cancel />}
            onClick={cancelHandler}
          />
          <PrimaryButton text={"Save"} icon={<Save />} onClick={saveHandler} />
        </div>
      </div>
    </div>
  );
}

export default Addnew;
