import React, { useEffect, useState } from "react";
import { 
  Cpu, 
  Server, 
  Clock, 
  HardDrive, 
  Activity, 
  Network,
  RefreshCcw
} from "lucide-react";
import { BASE_URL } from "../../Service/service";
import { color } from "framer-motion";
import { Colors } from "../../Colors";

const styles = {
  container: {
    minHeight: "100vh",
    // background: `linear-gradient(135deg, ${Colors.yellow2} 0%, ${Colors.blue1} 100%)`,
    position: "relative",
    overflow: "hidden",
  },
  backgroundPattern: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.4,
    background: `
      linear-gradient(45deg, ${Colors.yellow25} 25%, transparent 25%),
      linear-gradient(-45deg, ${Colors.yellow25} 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, ${Colors.yellow25} 75%),
      linear-gradient(-45deg, transparent 75%, ${Colors.yellow25} 75%)
    `,
    backgroundSize: "20px 20px",
    backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px",
    pointerEvents: "none",
  },
  wrapper: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "24px",
    position: "relative",
    zIndex: 1,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "32px",
    padding: "16px 24px",
    background: Colors.greyWhite,
    borderRadius: "16px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.05)",
    border: `1px solid ${Colors.grey95}`,
  },
  title: {
    fontSize: "32px",
    fontWeight: "bold",
    background: Colors.LinearGradient,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  refreshButton: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "10px 20px",
    background: Colors.LinearGradient,
    border: "none",
    borderRadius: "12px",
    cursor: "pointer",
    transition: "all 0.3s ease",
    color: Colors.grey16,
    fontWeight: "600",
    boxShadow: `0 2px 4px ${Colors.yellow50}50`,
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: `0 4px 8px ${Colors.yellow50}70`,
    },
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
    gap: "24px",
  },
  card: {
    background: Colors.greyWhite,
    borderRadius: "16px",
    padding: "24px",
    position: "relative",
    overflow: "hidden",
    border: `1px solid ${Colors.grey95}`,
    transition: "all 0.3s ease",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "4px",
      background: Colors.LinearGradient,
      opacity: 0,
      transition: "opacity 0.3s ease",
    },
    "&:hover": {
      transform: "translateY(-4px)",
      boxShadow: `0 8px 16px rgba(0, 0, 0, 0.1)`,
      "&::before": {
        opacity: 1,
      },
    },
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    marginBottom: "20px",
  },
  cardTitle: {
    fontSize: "18px",
    fontWeight: "600",
    color: Colors.grey28,
  },
  infoGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(140px, 1fr))",
    gap: "16px",
  },
  infoItem: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    padding: "12px",
    background: Colors.grey98,
    borderRadius: "8px",
    transition: "background-color 0.2s ease",
    "&:hover": {
      background: Colors.blue1,
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: "500",
    color: Colors.grey44,
  },
  value: {
    fontSize: "14px",
    color: Colors.grey28,
    fontWeight: "600",
  },
  progressContainer: {
    marginBottom: "20px",
  },
  progressBar: {
    width: "100%",
    height: "8px",
    backgroundColor: Colors.grey95,
    borderRadius: "4px",
    overflow: "hidden",
  },
  progressFill: (value) => ({
    width: `${value}%`,
    height: "100%",
    background: Colors.LinearGradient,
    transition: "width 0.3s ease",
  }),
  networkInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    color: Colors.blue50,
  },
  error: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    color: Colors.pink100,
  },
  lastUpdated: {
    textAlign: "center",
    marginTop: "32px",
    padding: "12px",
    background: Colors.greyWhite,
    borderRadius: "8px",
    fontSize: "14px",
    color: Colors.grey44,
    border: `1px solid ${Colors.grey95}`,
  },
};

const ProgressBar = ({ value }) => (
  <div style={styles.progressBar}>
    <div style={styles.progressFill(value)} />
  </div>
);

const MetricCard = ({ icon: Icon, title, children }) => {
  const [isHovered, setIsHovered] = useState(false);

  const cardStyle = {
    ...styles.card,
    transform: isHovered ? 'translateY(-2px)' : 'none',
    boxShadow: isHovered ? '0 4px 6px rgba(0,0,0,0.1)' : styles.card.boxShadow,
  };

  return (
    <div 
      style={cardStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={styles.cardHeader}>
        <Icon size={20} />
        <h3 style={styles.cardTitle}>{title}</h3>
      </div>
      {children}
    </div>
  );
};

const SystemMonitorDashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BASE_URL}/system-info`);
      const newData = await response.json();
      setData(newData);
      setLastUpdated(new Date());
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 30000);
    return () => clearInterval(interval);
  }, []);

  if (loading) return (
    <div style={styles.loading}>
      <RefreshCcw style={{ animation: 'spin 1s linear infinite' }} />
    </div>
  );
  
  if (error) return <div style={styles.error}>Error: {error.message}</div>;
  if (!data) return null;

  const memoryUsagePercent = ((parseFloat(data.totalMemory) - parseFloat(data.freeMemory)) / parseFloat(data.totalMemory)) * 100;
  const cpuUsagePercent = ((parseFloat(data.cpuUsage.user) + parseFloat(data.cpuUsage.system)) / 100) * 100;

  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <div style={styles.header}>
        <h1 style={{ margin: "24px", display: "flex", justifyContent: "center" }}>
        System Monitor
        </h1>
  
          <button 
            style={styles.refreshButton} 
            onClick={fetchData}
          >
            <RefreshCcw size={16} />
            Refresh
          </button>
        </div>

        <div style={styles.grid}>
          <MetricCard icon={Server} title="System Information">
            <div style={styles.infoGrid}>
              <div style={styles.infoItem}>
                <span style={styles.label}>Hostname</span>
                <span style={styles.value}>{data.hostname}</span>
              </div>
              <div style={styles.infoItem}>
                <span style={styles.label}>Platform</span>
                <span style={styles.value}>{data.platform}</span>
              </div>
              <div style={styles.infoItem}>
                <span style={styles.label}>Architecture</span>
                <span style={styles.value}>{data.architecture}</span>
              </div>
              <div style={styles.infoItem}>
                <span style={styles.label}>Release</span>
                <span style={styles.value}>{data.release}</span>
              </div>
            </div>
          </MetricCard>

          <MetricCard icon={Cpu} title="CPU Usage">
            <div style={styles.progressContainer}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span style={styles.label}>Utilization</span>
                <span style={styles.value}>{cpuUsagePercent.toFixed(1)}%</span>
              </div>
              <ProgressBar value={cpuUsagePercent} />
            </div>
            <div style={styles.infoGrid}>
              <div style={styles.infoItem}>
                <span style={styles.label}>Cores</span>
                <span style={styles.value}>{data.cpuCores}</span>
              </div>
              <div style={styles.infoItem}>
                <span style={styles.label}>Load Average</span>
                <span style={styles.value}>{data.cpuLoad.join(", ")}</span>
              </div>
            </div>
          </MetricCard>

          <MetricCard icon={HardDrive} title="Memory Usage">
            <div style={styles.progressContainer}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span style={styles.label}>Memory</span>
                <span style={styles.value}>{memoryUsagePercent.toFixed(1)}%</span>
              </div>
              <ProgressBar value={memoryUsagePercent} />
            </div>
            <div style={styles.infoItem}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={styles.label}>Total</span>
                <span style={styles.value}>{data.totalMemory}</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={styles.label}>Free</span>
                <span style={styles.value}>{data.freeMemory}</span>
              </div>
            </div>
          </MetricCard>

          <MetricCard icon={Network} title="Network Interfaces">
            <div style={styles.networkInfo}>
              {Object.entries(data.networkInterfaces).map(([name, interfaces]) => (
                <div key={name} style={styles.infoItem}>
                  <span style={styles.label}>{name}</span>
                  {interfaces.map((int, index) => (
                    <span key={index} style={styles.value}>
                      {int.family}: {int.address}
                    </span>
                  ))}
                </div>
              ))}
            </div>
          </MetricCard>

          <MetricCard icon={Clock} title="System Uptime">
            <div style={{ fontSize: '20px', fontWeight: '600', color: '#334155' }}>
              {data.uptime}
            </div>
          </MetricCard>

          <MetricCard icon={Activity} title="User Information">
            <div style={styles.infoItem}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={styles.label}>Username</span>
                <span style={styles.value}>{data.userInfo.username}</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={styles.label}>Shell</span>
                <span style={styles.value}>{data.userInfo.shell}</span>
              </div>
              <div style={styles.label}>
                Home Directory:
                <span style={{ ...styles.value, marginLeft: '8px' }}>{data.userInfo.homedir}</span>
              </div>
            </div>
          </MetricCard>
        </div>

        <div style={styles.lastUpdated}>
          Last Updated: {lastUpdated.toLocaleString()}
        </div>
      </div>
    </div>
  );
};

export default SystemMonitorDashboard;