import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import { BsSearch } from "react-icons/bs";
import InactiveButton from '../../../Components/UTIL/Buttons/InactiveButton'
import { AiOutlineSortAscending } from "react-icons/ai";
import Switch from "react-switch";

import { useDispatch, useSelector } from 'react-redux';
import { productAction } from '../../../store/product';
import useTable from '../../Create/useTable';
import { Colors } from '../../../Colors';

// import CounterInput from "react-counter-input";
import Veg_Non_Veg from '../../../SVG/Veg_Non_Veg';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { addonsAction } from '../../../store/addons';
import VegIcon from '../../../SVG/VegIcon';
import Non_Veg from '../../../SVG/Non_Veg';
import CancelInactive from '../../../SVG/CancelInactive';
import UpdateInactive from '../../../SVG/UpdateInactive';

function AddonsTomorrowActive(props) {
    const styles = {
        table: {
            border: '1px solid #E9E9E9',
            color: Colors.deepBlue100
        },
        tableHead: {
            backgroundColor: Colors.yellow25,
        },
        tableBody: {
            backgroundColor: 'white',
        },
        outerStepperContainer: {
            display: 'flex',
            // backgroundColor: 'red',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20%'
        },
        stepperContainer: {
            background: Colors.LinearGradient,
            height: '30px',
            width: '30px',
            borderRadius: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: Colors.deepBlue100,
            fontWeight: 800,
            fontSize: '16px'
        },
        inputStyle: {
            color: Colors.deepBlue100,
            fontWeight: 500,
            outlineStyle: 'none',
            border: 'none'
        },
        modalContainer: {

            boxShadow: '0px 2px 2px #9E9E9E',
            fontSize: '12px',
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-around',
            position: 'absolute',
            backgroundColor: 'white',
            border: `1px solid ${Colors.yellow100}`,
            borderRadius: 8
        },
        modalItemContainer: {
            cursor: "pointer",
            margin: '4px',
        },
        h2: {
            fontSize: '24px',
            fontWeight: '700'
        },
        innerContainer: {
            display: "flex",
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem'
        },
        buttonContainer: {
            display: "flex",
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        buttonInnerContainer:{
            marginRight:'1rem'
        }
    }

    const addonsTomorrowActive = useSelector(state => state.addons.addonsTomorrowActive)
    // console.log(addonsTomorrowActive);
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false)
    const [counter, setCounter] = useState(0)
    const [checked, setChecked] = useState(false)





    const checkedHandler = () => {
        setChecked(!checked)
        dispatch(addonsAction.tomorrowActiveAllSelectHandler(checked))
    }
    const checkedOneHandler = (id, event) => {
        dispatch(addonsAction.tomorrowActiveOneItemSelectHandler({ id: id, event: event }))
    }


    const switchHandleChange = (id) => {
        const fdata = addonsTomorrowActive.filter((item) => {
            return item.id !== id
        })

        const sdata = addonsTomorrowActive.filter((item) => {
            return item.id === id
        })
        dispatch(addonsAction.addonActiveToInactive({ activeToInActive: sdata, active: fdata }))
    }
    const vegTypeHandler = () => {
        setModal(!modal)
    }
    const modalItemClickHandler = (selected) => {
        dispatch(addonsAction.tomorrowActiveModalItemClickHandler(selected))
        setModal(false)
    }
    return (
        <div>
            <div style={styles.innerContainer}>
                <h2 style={styles.h2}>Active Addons</h2>
                <div style={styles.buttonContainer}>
                    <div style={styles.buttonInnerContainer}>
                        <InactiveButton text="Cancel" icon={<CancelInactive/>}/>
                    </div>
                    <div>
                        <InactiveButton text="Update" icon={<UpdateInactive/>} />
                    </div>
                </div>
            </div>
            <Table bordered hover className={styles.table}>
                <thead style={styles.tableHead}>
                    <tr>
                        <th>
                            <input type="checkbox" checked={checked} onChange={checkedHandler} />
                        </th>
                        <th ><Veg_Non_Veg /><RiArrowDropDownLine style={styles.icon} onClick={vegTypeHandler} />
                            {modal && (
                                <div style={styles.modalContainer}>
                                    <div>
                                        <div style={styles.modalItemContainer} onClick={() => { modalItemClickHandler('All') }} id="clickable">All</div>
                                        <div style={styles.modalItemContainer} onClick={() => { modalItemClickHandler('Veg') }} id="clickable">Veg</div>
                                        <div style={styles.modalItemContainer} onClick={() => { modalItemClickHandler('Non-Veg') }} id="clickable">Non-Veg</div>
                                    </div>
                                </div>
                            )}</th>
                        <th>Status</th>
                        <th>Product Name </th>
                        <th>Type </th>
                        <th>Ingredient</th>
                        <th>Price</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody style={styles.tableBody}>
                    {addonsTomorrowActive.map((item, index) => (
                        <tr key={index}>
                            <td><input type="checkbox" checked={item.checked} onChange={(e) => checkedOneHandler(index, e)} /></td>
                            <td>{item.vegType === "Veg" ? <VegIcon /> : <Non_Veg />}</td>
                            <td>{item.status}
                                <Switch
                                    onChange={() => switchHandleChange(item.id)}
                                    checked={item.status}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    onColor='#FFD814'
                                    onHandleColor={Colors.yellow1}
                                />
                            </td>
                            <td>{item.name}</td>
                            <td>{item.type}</td>
                            <td>{item.ingredient}</td>
                            <td>{item.price}</td>
                            <td>
                                {/* <CounterInput
                                    min={0}
                                    max={10}
                                    count={item.count}
                                    btnStyle={styles.stepperContainer}
                                    inputStyle={styles.inputStyle}
                                    onCountChange={count => setCounter(count)}
                                /> */}

                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div >
    )
}

export default AddonsTomorrowActive