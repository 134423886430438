import React, { useState, useEffect } from 'react';
import { LineChart, Line, BarChart, Bar, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Colors } from '../../Colors';

const MonthlyReport = ({ data }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reportData, setReportData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [chartType, setChartType] = useState('line');
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    if (startDate && endDate) {
      // Create dates at the start of the day for start date and end of the day for end date
      const startDateTime = new Date(startDate);
      startDateTime.setHours(0, 0, 0, 0);
      
      const endDateTime = new Date(endDate);
      endDateTime.setHours(23, 59, 59, 999);

      const filtered = data.filter(order => {
        const orderDate = new Date(order.subscription_details.currentTime);
        return orderDate >= startDateTime && orderDate <= endDateTime;
      });

      setFilteredOrders(filtered);

      // Group data by date and calculate daily totals
      const groupedData = filtered.reduce((acc, order) => {
        const date = new Date(order.subscription_details.currentTime).toISOString().split('T')[0];
        if (!acc[date]) {
          acc[date] = { 
            date, 
            totalAmount: 0, 
            count: 0,
            deliveryCharge: 0,
            subtotal: 0,
            discount: 0 
          };
        }
        acc[date].totalAmount += parseFloat(order.subscription_details.totalPricePaid) || 0;
        acc[date].count += 1;
        acc[date].deliveryCharge += parseFloat(order.deliverycharge) || 0;
        acc[date].discount += parseFloat(order.discount) || 0;
        acc[date].subtotal += parseFloat(order.subscription_details.totalPricePaid) || 0;
        return acc;
      }, {});

      // Fill in missing dates between start and end date
      const allDates = [];
      const currentDate = new Date(startDateTime);
      while (currentDate <= endDateTime) {
        const dateStr = currentDate.toISOString().split('T')[0];
        if (!groupedData[dateStr]) {
          groupedData[dateStr] = {
            date: dateStr,
            totalAmount: 0,
            count: 0,
            deliveryCharge: 0,
            subtotal: 0,
            discount: 0
          };
        }
        allDates.push(dateStr);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      const sortedData = allDates.map(date => ({
        ...groupedData[date],
        date: formatDateForDisplay(date)
      }));

      setReportData(sortedData);

      // Calculate total amount with proper number handling
      const total = filtered.reduce((sum, order) => {
        const amount = parseFloat(order.subscription_details.totalPricePaid) || 0;
        return sum + amount;
      }, 0);
      
      setTotalAmount(total.toFixed(2));
    }
  }, [startDate, endDate, data]);

  const formatDateForDisplay = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const handlePrint = () => {
    window.print();
  };

  const getCurrentDate = () => {
    const now = new Date();
    return now.toISOString().split('T')[0];
  };

  const renderChart = () => {
    const ChartComponent = chartType === 'bar' ? BarChart : chartType === 'area' ? AreaChart : LineChart;
    const DataComponent = chartType === 'bar' ? Bar : chartType === 'area' ? Area : Line;

    return (
      <ResponsiveContainer width="100%" height={300}>
        <ChartComponent data={reportData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
          <Tooltip />
          <Legend />
          <DataComponent 
            yAxisId="left" 
            type="monotone" 
            dataKey="totalAmount" 
            stroke="#8884d8" 
            fill="#8884d8" 
            name="Daily Revenue" 
          />
          <DataComponent 
            yAxisId="right" 
            type="monotone" 
            dataKey="count" 
            stroke="#82ca9d" 
            fill="#82ca9d" 
            name="Order Count" 
          />
        </ChartComponent>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="monthly-report" style={styles.container}>
      <h2 style={styles.title}>Date Range Report</h2>
      <div style={styles.controls}>
        <div style={styles.dateInputs}>
          <label style={styles.label}>
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              max={endDate || getCurrentDate()}
              style={styles.input}
            />
          </label>
          <label style={styles.label}>
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              min={startDate}
              max={getCurrentDate()}
              style={styles.input}
            />
          </label>
        </div>
        <div style={styles.chartControls}>
          <select
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
            style={styles.select}
          >
            <option value="line">Line Chart</option>
            <option value="bar">Bar Chart</option>
            <option value="area">Area Chart</option>
          </select>
          <button onClick={handlePrint} style={styles.button}>Print Report</button>
        </div>
      </div>
      {startDate && endDate && reportData.length > 0 && (
        <div style={styles.reportContent}>
          <h3 style={styles.subtitle}>
            Report from {formatDateForDisplay(startDate)} to {formatDateForDisplay(endDate)}
          </h3>
          <p style={styles.totalRevenue}>Total Revenue: ₹{totalAmount}</p>
          <div style={styles.chartContainer}>
            {renderChart()}
          </div>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>No</th>
                <th style={styles.tableHeader}>Date & Time</th>
                <th style={styles.tableHeader}>First Name</th>
                <th style={styles.tableHeader}>Phone Number</th>
                <th style={styles.tableHeader}>Order ID</th>
                <th style={styles.tableHeader}>Start Date</th>
                <th style={styles.tableHeader}>End Date</th>
                <th style={styles.tableHeader}>Delivery Charge</th>
                <th style={styles.tableHeader}>SubTotal</th>
                <th style={styles.tableHeader}>Discount</th>
                <th style={styles.tableHeader}>Payment id</th>
                <th style={styles.tableHeader}>Total Paid</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order, index) => (
                <tr key={order.orderid || index}>
                  <td style={styles.tableCell}>{index + 1}</td>
                  <td style={styles.tableCell}>
                    {formatDateTime(order.subscription_details.currentTime)}
                  </td>
                  <td style={styles.tableCell}>{order.userdata.first_name}</td>
                  <td style={styles.tableCell}>{order.userdata.phone_number}</td>
                  <td style={styles.tableCell}>{order.orderid}</td>
                  <td style={styles.tableCell}>{order.subscription_details.startDate}</td>
                  <td style={styles.tableCell}>{order.subscription_details.endDate}</td>
                  <td style={styles.tableCell}>₹{order.deliverycharge || '0'}</td>
                  <td style={styles.tableCell}>₹{order.subscription_details.totalPricePaid || '0'}</td>
                  <td style={styles.tableCell}>₹{order.discount || '0'}</td>
                  <td style={styles.tableCell}>₹{order.subscription_details.totalPricePaid || '0'}</td>
                  <td style={styles.tableCell}>₹{order.subscription_details.transactionId}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="10" style={{...styles.tableCell, textAlign: 'right', fontWeight: 'bold'}}>Total:</td>
                <td style={{...styles.tableCell, fontWeight: 'bold'}}>₹{totalAmount}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};


const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    flexWrap: 'wrap',
  },
  dateInputs: {
    display: 'flex',
    gap: '10px',
    marginBottom: '10px',
  },
  chartControls: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
  },
  input: {
    padding: '8px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    marginTop: '4px',
  },
  select: {
    padding: '8px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '8px 16px',
    fontSize: '16px',
    background: Colors.LinearGradient,
    color: 'black',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  reportContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  totalRevenue: {
    fontSize: '18px',
    marginBottom: '20px',
  },
  chartContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
  },
  tableHeader: {
    border: '1px solid #dddddd',
    padding: '12px',
    backgroundColor: Colors.deepBlue100,
    color: 'black',
    textAlign: 'left',
  },
  tableCell: {
    border: '1px solid #dddddd',
    padding: '8px',
    textAlign: 'left',
  },
};

export default MonthlyReport;