import React, { useState, useEffect } from "react";
import { FaSearch, FaWhatsapp, FaCog } from "react-icons/fa";
import axios from "axios";
import { BASE_URL } from "../../Service/service";
import { Colors } from "../../Colors";
import { color } from "framer-motion";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAdmins, setShowAdmins] = useState(false);
  const [showUsers, setShowUsers] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showColumnSettings, setShowColumnSettings] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    id: true,
    name: true,
    phone: true,
    route: true,
    email: false,
    createdAt: true,
    houseFlatNo: true,
    roadLocation: true,
    locality: true,
    version: true,
    platform: false,
    userStatus: true,
    actions: false
  });

  // Column definitions with labels
  const columnDefinitions = {
    id: "ID",
    name: "Name",
    phone: "Phone",
    route: "Route",
    email: "Email",
    createdAt: "Created At",
    houseFlatNo: "House & Flat",
    roadLocation: "Road & Location",
    locality: "Locality",
    version: "Version",
    platform: "Platform",
    userStatus: "User Status",
    actions: "Actions"
  };

  // Load saved column preferences from localStorage on component mount
  useEffect(() => {
    const savedColumns = localStorage.getItem('userManagementColumns');
    if (savedColumns) {
      setVisibleColumns(JSON.parse(savedColumns));
    }
  }, []);

  // Save column preferences to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('userManagementColumns', JSON.stringify(visibleColumns));
  }, [visibleColumns]);

  // Fetch users data
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${BASE_URL}/users`);
        const sortedUsers = response.data.sort((a, b) => a.id - b.id);
        setUsers(sortedUsers);
        setError(null);
      } catch (err) {
        setError("Failed to fetch users");
        console.error("Error fetching users:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const toggleColumn = (columnKey) => {
    setVisibleColumns(prev => ({
      ...prev,
      [columnKey]: !prev[columnKey]
    }));
  };

  const toggleAllColumns = (value) => {
    const newColumns = {};
    Object.keys(visibleColumns).forEach(key => {
      newColumns[key] = value;
    });
    setVisibleColumns(newColumns);
  };

  // Filter users based on search and type
  const getFilteredUsers = () => {
    return users.filter((user) => {
      const matchesSearch = 
        user.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.phone_number?.includes(searchTerm);
      
      const matchesUserType = 
        (showAdmins && user.user_info === "Admin") ||
        (showUsers && user.user_info !== "Admin");
      
      return matchesSearch && matchesUserType;
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(dateString).toLocaleString("en-US", options);
  };

  const sendWhatsAppMessage = (user) => {
    const cleanNumber = user.phone_number?.replace(/\D/g, "");
    if (!cleanNumber) {
      console.error("Invalid phone number");
      return;
    }
    
    const numberWithCountryCode = cleanNumber.startsWith("91")
      ? cleanNumber
      : `91${cleanNumber}`;

    const message = `Dear ${user.first_name},

We hope you're enjoying Lalas Kitchen services! 🌟

We've made some exciting improvements to enhance your experience with our app. To ensure you get the best service, kindly update your My Lalas Kitchen app to the latest version.

*What's New:*
• Improved user interface
• Faster order processing
• Better performance

Update now:


Thank you for being a valued customer!

Best regards,
Team Lalas Kitchen`;

    const whatsappURL = `https://wa.me/${numberWithCountryCode}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank");
  };

  const ColumnCustomizationMenu = () => (
    <div style={styles.columnMenu}>
      <div style={styles.columnMenuHeader}>
        <h3>Customize</h3>
        <button 
          style={styles.closeButton}
          onClick={() => setShowColumnSettings(false)}
        >
          ×
        </button>
      </div>
      {/* <div style={styles.selectAllContainer}>
        <button 
          onClick={() => toggleAllColumns(true)}
          style={{marginRight:12,backgroundColor:Colors.yellow100}}
        >
          Select All
        </button>
        <button 
          onClick={() => toggleAllColumns(false)}
          style={{backgroundColor:Colors.yellow100}}
        >
          Deselect All
        </button>
      </div> */}
      {Object.entries(columnDefinitions).map(([key, label]) => (
        <label key={key} style={styles.columnMenuItem}>
          <input
            type="checkbox"
            checked={visibleColumns[key]}
            onChange={() => toggleColumn(key)}
            style={styles.columnCheckbox}
          />
          <span style={styles.columnLabel}>{label}</span>
        </label>
      ))}
    </div>
  );

  const renderUserTable = (filteredUsers, title) => {
    if (loading) {
      return <div style={styles.loadingMessage}>Loading users...</div>;
    }

    if (error) {
      return <div style={styles.errorMessage}>{error}</div>;
    }

    if (filteredUsers.length === 0) {
      return <div style={styles.emptyMessage}>No users found</div>;
    }

    return (
      <div style={styles.tableSection}>
        <h2 style={styles.sectionTitle}>{title}</h2>
        <div style={styles.tableWrapper}>
          <table style={styles.table}>
            <thead>
              <tr>
                {visibleColumns.id && <th style={styles.tableHeader}>ID</th>}
                {visibleColumns.name && <th style={styles.tableHeader}>Name</th>}
                {visibleColumns.phone && <th style={styles.tableHeader}>Phone</th>}
                {visibleColumns.route && <th style={styles.tableHeader}>Route</th>}
                {visibleColumns.email && <th style={styles.tableHeader}>Email</th>}
                {visibleColumns.createdAt && <th style={styles.tableHeader}>Created At</th>}
                {visibleColumns.houseFlatNo && <th style={styles.tableHeader}>House & Flat</th>}
                {visibleColumns.roadLocation && <th style={styles.tableHeader}>Road & Location</th>}
                {visibleColumns.locality && <th style={styles.tableHeader}>Locality</th>}
                {visibleColumns.version && <th style={styles.tableHeader}>Version</th>}
                {visibleColumns.platform && <th style={styles.tableHeader}>Platform</th>}
                {visibleColumns.userStatus && <th style={styles.tableHeader}>User Status</th>}
                {visibleColumns.actions && <th style={styles.tableHeader}>Update Push</th>}
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, index) => (
                <tr key={user.id} style={styles.tableRow}>
                  {visibleColumns.id && <td style={styles.tableCell}>{index + 1}</td>}
                  {visibleColumns.name && <td style={styles.tableCell}>{user.first_name || 'N/A'}</td>}
                  {visibleColumns.phone && <td style={styles.tableCell}>{user.phone_number || 'N/A'}</td>}
                  {visibleColumns.route && <td style={styles.tableCell}>{user.route || 'N/A'}</td>}
                  {visibleColumns.email && <td style={styles.tableCell}>{user.last_name || 'N/A'}</td>}
                  {visibleColumns.createdAt && <td style={styles.tableCell}>{formatDate(user.created_at)}</td>}
                  {visibleColumns.houseFlatNo && <td style={styles.tableCell}>{user.house_name || 'N/A'}</td>}
                  {visibleColumns.roadLocation && <td style={styles.tableCell}>{user.road_and_location || 'N/A'}</td>}
                  {visibleColumns.locality && <td style={styles.tableCell}>{user.locality || 'N/A'}</td>}
                  {visibleColumns.version && <td style={styles.tableCell}>{user.app_version || 'Old Ver.'}</td>}
                  {visibleColumns.platform && <td style={styles.tableCell}>{user.platform || 'Android'}</td>}
                  {visibleColumns.userStatus && (
                    <td style={styles.tableCell}>
                      <span style={user.loginstatus ? styles.statusActive : styles.statusInactive}>
                        {user.loginstatus ? "Logged In" : "Not Logged In"}
                      </span>
                    </td>
                  )}
                  {visibleColumns.actions && (
                    <td style={styles.tableCell}>
                      <button
                        onClick={() => sendWhatsAppMessage(user)}
                        style={styles.whatsappButton}
                      >
                        <FaWhatsapp style={{ marginRight: '8px' }} />
                        Send
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const filteredUsers = getFilteredUsers();
  const adminUsers = filteredUsers.filter(user => user.user_info === "Admin");
  const regularUsers = filteredUsers.filter(user => user.user_info !== "Admin");

  return (
    <div style={styles.container}>
      <h1 style={{ margin: "24px", display: "flex", justifyContent: "center" }}>
      User Management
        </h1>
      <div style={styles.headerContainer}>
        <div style={styles.searchContainer}>
          <FaSearch style={styles.searchIcon} />
          <input
            type="text"
            placeholder="Search by name or phone number"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={styles.searchInput}
          />
        </div>
        
        <div style={styles.controlsContainer}>
          <div style={styles.filterContainer}>
            <label style={styles.filterLabel}>
              <input
                type="checkbox"
                checked={showAdmins}
                onChange={() => setShowAdmins(!showAdmins)}
                style={styles.checkbox}
              />
              <span style={styles.labelText}>Show Delivery Users</span>
            </label>
            <label style={styles.filterLabel}>
              <input
                type="checkbox"
                checked={showUsers}
                onChange={() => setShowUsers(!showUsers)}
                style={styles.checkbox}
              />
              <span style={styles.labelText}>Show Users</span>
            </label>
          </div>
          
          <div style={styles.columnCustomization}>
            <button
              onClick={() => setShowColumnSettings(!showColumnSettings)}
              style={styles.columnSettingsButton}
            >
              <FaCog style={styles.settingsIcon} />
            </button>
            {showColumnSettings && <ColumnCustomizationMenu />}
          </div>
        </div>
      </div>

      {showAdmins && renderUserTable(adminUsers, "Delivery Users")}
      {showUsers && renderUserTable(regularUsers, "Regular Users")}
    </div>
  );
};



const styles = {
  container: {
    minHeight: '100vh',
    paddingBottom: '2rem',
  },
  mainTitle: {
    textAlign: 'center',
    padding: '1.25rem',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.25rem 2rem',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    background:"linear-gradient(#FAC105, #FFD814)",
    borderRadius: '0.5rem',
    padding: '0.5rem',
  },
  searchIcon: {
    color: 'white',
    marginRight: '0.5rem',
  },
  searchInput: {
    padding: '0.5rem',
    borderRadius: '0.25rem',
    border: 'none',
    width: '16rem',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  filterLabel: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  checkbox: {
    marginRight: '0.5rem',
  },
  labelText: {
    marginLeft: '0.5rem',
  },
  tableSection: {
    marginBottom: '2.5rem',
    padding: '0 2rem',
  },
  loadingMessage: {
    textAlign: 'center',
    padding: '2rem',
    fontSize: '1.1rem',
    color: '#666',
  },
  errorMessage: {
    textAlign: 'center',
    padding: '2rem',
    color: '#ef4444',
    fontSize: '1.1rem',
  },
  emptyMessage: {
    textAlign: 'center',
    padding: '2rem',
    color: '#666',
    fontSize: '1.1rem',
  },
  sectionTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    backgroundColor: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  },
  tableHeader: {
    padding: '0.75rem',
    background: 'linear-gradient(#FAC105, #FFD814)',
    textAlign: 'left',
    border: '1px solid #e5e7eb',
    fontWeight: 'bold',
  },
  tableRow: {
    borderBottom: '1px solid #e5e7eb',
    ':hover': {
      backgroundColor: '#f9fafb',
    },
  },
  tableCell: {
    padding: '0.75rem',
    border: '1px solid #e5e7eb',
  },
  statusActive: {
    color: '#10b981',
    fontWeight: '500',
  },
  statusInactive: {
    color: '#ef4444',
    fontWeight: '500',
  },
  whatsappButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '0.5rem',
    backgroundColor: '#25D366',
    color: 'white',
    border: 'none',
    borderRadius: '0.25rem',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    ':hover': {
      backgroundColor: '#128C7E',
    },
  },
  controlsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  columnCustomization: {
    position: 'relative',
  },
  columnSettingsButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.5rem 1rem',
    backgroundColor: '#3b82f6',
    color: 'white',
    border: 'none',
    borderRadius: '0.25rem',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  settingsIcon: {
    fontSize: '1rem',

  },
  columnMenu: {
    position: 'absolute',
    top: '100%',
    right: 0,
    width: '250px',
    backgroundColor: 'white',
    border: '1px solid #e5e7eb',
    borderRadius: '0.5rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    padding: '1rem',
    marginTop:"12px"
  },
  columnMenuHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    paddingBottom: '0.5rem',
    borderBottom: '1px solid #e5e7eb',
  },
  closeButton: {
    background: 'none',
    border: 'none',
    fontSize: '1.5rem',
    cursor: 'pointer',
    color: '#666',
  },
  columnMenuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 0',
    cursor: 'pointer',
  },
  columnCheckbox: {
    marginRight: '0.75rem',
  },
  columnLabel: {
    fontSize: '0.875rem',
    color: '#374151',
  }
};

export default UserManagement;